<template>
  <div>
    <FilterAlboSnm
      @getAlboSnmList="searchAlboSnmList"
      @resetFilters="resetFilters"
    />
    <TableAlboSnm
      @getAlboSnmList="getAlboSnmList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";

import FilterAlboSnm from "@/components/components-fit/SNM/albo-snm/FiltriAlboSnm.vue";
import TableAlboSnm from "@/components/components-fit/SNM/albo-snm/TableAlboSnm.vue";

export default defineComponent({
  name: "albo-snm",
  components: {
    FilterAlboSnm,
    TableAlboSnm,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Albo ISF", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    // const stagione = computed(() => store.getters.stagioneSelected);
    const rowsToSkip = computed(() => store.getters.rowsToSkipAlboSnm);
    const fetchRows = computed(() => store.getters.fetchRowsAlboSnm);
    const sortColumn = computed(() => store.getters.sortColumnAlboSnm);
    const sortOrder = computed(() => store.getters.sortOrderAlboSnm);
    const tutteStagioniAlbo = computed(() => store.getters.tutteStagioniAlbo);

    const stagione = computed(() => store.getters.stagioneSelected);
    const nome = computed(() => store.getters.nomeAlboSnm);
    const cognome = computed(() => store.getters.cognomeAlboSnm);
    const comitato = computed(() => store.getters.comitatoAlboSnm);
    const codice = computed(() => store.getters.codiceSnmAlboSnm);

    const getAlboSnmList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          stagione: tutteStagioniAlbo.value ? 10000 : stagione.value,
          nome: trimInput(nome.value),
          cognome: trimInput(cognome.value),
          comitato: comitato.value,
          codice: codice.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.ALBO_LIST_SNM,
        itemName: "albo_list_snm",
      });
    };
    getAlboSnmList();

    const resetFilters = () => {
      store.commit("resetFiltersAlboSnm");
      getAlboSnmList();
    };
    const loaded = computed(() =>
      store.getters.getStateFromName("loadedalbo_list_snm")
    );
    const searchAlboSnmList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipAlboSnm");
      getAlboSnmList();
    };

    return {
      getAlboSnmList,
      resetFilters,
      searchAlboSnmList,
      tutteStagioniAlbo,
    };
  },
});
</script>
