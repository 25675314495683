<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getAlboSnmList')">
        <div class="row">
          <div class="col-sm-3 filter">
            <input
              type="text"
              class="form-control"
              placeholder="Cognome"
              aria-label=""
              :value="cognomeAlboSnm"
              @input="setCognomeAlboSnm"
            />
          </div>
          <div class="col-sm-3 filter">
            <input
              type="text"
              class="form-control"
              placeholder="Nome"
              aria-label=""
              :value="nomeAlboSnm"
              @input="setNomeAlboSnm"
            />
          </div>

          <div class="col-sm-3 filter">
            <input
              type="text"
              class="form-control"
              placeholder="Codice SNM"
              aria-label=""
              :value="codiceSnmAlboSnm"
              @input="setCodiceSnmAlboSnm"
            />
          </div>
          <div class="col-sm-3 filter">
            <SelectInput
              :options="comitati"
              name="comitati"
              placeholder="Comitati"
              :value="comitatoAlboSnm"
              @changeSelect="setComitatoAlboSnm($event)"
            />
          </div>
        </div>
        <div class="text-center mt-5">
          <span
            type="button"
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="$emit('getAlboSnmList')"
            >Cerca <i class="bi bi-search fs-6 text-gray-600"> </i
          ></span>
          <span
            type="button"
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="$emit('resetFilters')"
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-albo-snm",
  emits: ["resetFilters", "getAlboSnmList"],
  components: {
    SelectInput,
  },
  props: {
    campiLocked: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const comitati = computed(() => store.getters.getStateFromName("comitati"));

    const keys = ref("c");
    if (!comitati.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const nomeAlboSnm = computed(() => store.getters.nomeAlboSnm);
    const setNomeAlboSnm = (event) => {
      const string = event.target.value;
      store.commit("setNomeAlboSnm", string);
    };

    const cognomeAlboSnm = computed(() => store.getters.cognomeAlboSnm);
    const setCognomeAlboSnm = (event) => {
      const string = event.target.value;
      store.commit("setCognomeAlboSnm", string);
    };

    const codiceSnmAlboSnm = computed(() => store.getters.codiceSnmAlboSnm);
    const setCodiceSnmAlboSnm = (event) => {
      const string = event.target.value;
      store.commit("setCodiceSnmAlboSnm", string);
    };

    const comitatoAlboSnm = computed(() => store.getters.comitatoAlboSnm);
    const setComitatoAlboSnm = (event) => {
      store.commit("setComitatoAlboSnm", event);
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      emit("getAlboSnmList");
    });

    return {
      nomeAlboSnm,
      setNomeAlboSnm,
      cognomeAlboSnm,
      setCognomeAlboSnm,
      codiceSnmAlboSnm,
      setCodiceSnmAlboSnm,
      comitatoAlboSnm,
      setComitatoAlboSnm,
      comitati,
    };
  },
});
</script>

<style></style>
