<template>
  <div>
    <div class="row mt-5">
      <div class="col-4">
        <div v-if="!readOnly">
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add-albo-snm"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>inserimento rinnovo
            annuale albo
          </button>
          <AddAlboSnm @refreshList="$emit('getAlboSnmList')" />
        </div>
      </div>
      <div class="col-4 text-center">
        <div class="btn btn-light text-gray-700 fs-6 text-center">
          <input
            class="form-check-input me-2"
            type="checkbox"
            @click="setTutteLeStagioniAlbo"
            :checked="tutteStagioniAlbo"
          />visualizza tutte le stagioni
        </div>
      </div>
      <div class="col-4">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        albo_list_snm.length === 0 && loaded && (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="albo_list_snm"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsAlboSnm"
        @current-change="setCurrentPageAlboSnm"
      >
        <template v-slot:cell-stagione="{ row: data }">
          {{ data.stagione }}
        </template>
        <template v-slot:cell-nominativo_completo="{ row: data }"
          ><div class="tab-long">
            <router-link
              :to="`/persone/dettaglio-persona/anagrafica-persona/${data.id_persona}`"
            >
              {{ data.nominativo_completo }}</router-link
            >
          </div></template
        >
        <template v-slot:cell-numero_albo="{ row: data }">
          {{ data.numero_albo }}
        </template>
        <template v-slot:cell-codice_snm="{ row: data }">
          {{ data.codice_snm }}
        </template>
        <template v-slot:cell-qualifiche_pagate="{ row: data }">
          <div
            class="tab-long-xl my-2"
            v-for="qualifica in data.qualifiche"
            :key="qualifica.id_albo"
          >
            {{ qualifica.cql }}
            <span v-if="!qualifica.cql"> - </span>
          </div>
        </template>
        <template v-slot:cell-data_richiesta="{ row: data }">
          {{ data.data_richiesta }}
        </template>
        <template v-slot:cell-data_approvazione="{ row: data }">
          {{ data.data_approvazione }}
        </template>
        <template v-slot:cell-comitato="{ row: data }">
          {{ data.comitato }}
        </template>
        <template v-slot:cell-options="{ row: data }"
          >{{ data.options }}
          <div
            class="btn-group"
            @click="
              getCorsiAggiornamentoSnm(data.id_persona);
              selectedPersona = data.nominativo_completo;
            "
          >
            <button class="btn btn-sm dropdown p-1">
              <a
                href="#"
                class="navi-link"
                data-bs-toggle="modal"
                data-bs-target="#corsi_aggiornamento_snm"
              >
                <i class="bi bi-bookmark-check text-dark fs-5"></i>
              </a>
            </button></div
        ></template>
      </Datatable>
      <ListaCorsiAggiornamentoSnm
        :nominativo_persona="selectedPersona"
        :corsi_aggiornamento_snm_list="corsi_aggiornamento_snm_list"
        :loadingDetail="loadingDetail"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { ref, computed, getCurrentInstance } from "vue";
import AddAlboSnm from "./AddAlboSnm.vue";
import ListaCorsiAggiornamentoSnm from "./ListaCorsiAggiornamentoSnm.vue";
import { viewCorsiAggiornamentoList } from "@/requests/alboInsegnateRequests";
import isEnabled from "@/composables/isEnabled.js";

import { alertFailed } from "@/composables/swAlert";

export default {
  name: "TableAlboSnm",
  components: { Datatable, AddAlboSnm, ListaCorsiAggiornamentoSnm },
  emits: ["getAlboSnmList", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const selectedPersona = ref(null);

    const setTutteLeStagioniAlbo = () => {
      store.commit("setTutteLeStagioniAlbo");
      emit("getAlboSnmList");
    };

    const setFetchRowsAlboSnm = (e) => {
      store.commit("setFetchRowsAlboSnm", e);
      emit("getAlboSnmList");
    };
    const setCurrentPageAlboSnm = (page) => {
      store.commit("setCurrentPageAlboSnm", page);
      emit("getAlboSnmList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnAlboSnm", columnName);
      store.commit("setSortOrderAlboSnm", order);
      emit("getAlboSnmList");
    };

    /*  const getCorsiAggiornamentoSnm = (id_persona) => {
      store.dispatch("setStoreListData", {
        keys: {
          id_Persona: id_persona,
        },
        apiLink: globalApi.CORSI_AGGIORNAMENTO_SNM_LIST,
        itemName: "corsi_aggiornamento_snm_list",
      });
    }; */
    const loadingDetail = ref(false);
    const corsi_aggiornamento_snm_list = ref(null);
    const getCorsiAggiornamentoSnm = (id_persona) => {
      loadingDetail.value = true;
      viewCorsiAggiornamentoList(id_persona).then((res) => {
        loadingDetail.value = false;
        if (res.status == 200) {
          corsi_aggiornamento_snm_list.value = res.data.results;
        } else {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "corsi_aggiornamento_snm"
          );
        }
      });
    };

    const tableHeader = ref([
      {
        name: "Stagione",
        key: "stagione",
      },
      {
        name: "Persona",
        key: "nominativo_completo",
      },
      {
        name: "Numero Albo",
        key: "numero_albo",
      },
      {
        name: "Codice SNM",
        key: "codice_snm",
      },
      {
        name: "Qualifiche pagate",
        key: "qualifiche_pagate",
      },
      {
        name: "Data richiesta",
        key: "data_richiesta",
      },
      {
        name: "Data approvazione",
        key: "data_approvazione",
      },
      {
        name: "Comitato",
        key: "comitato",
      },
      { key: "options", sortable: false },
    ]);

    return {
      tableHeader,
      tutteStagioniAlbo: computed(() => store.getters.tutteStagioniAlbo),
      setTutteLeStagioniAlbo,
      albo_list_snm: computed(() =>
        store.getters.getStateFromName("resultsalbo_list_snm")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedalbo_list_snm")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordalbo_list_snm")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusalbo_list_snm")
      ),
      currentPage: computed(() => store.getters.currentPageAlboSnm),
      rowsToSkip: computed(() => store.getters.rowsToSkipAlboSnm),
      fetchRows: computed(() => store.getters.fetchRowsAlboSnm),
      sortColumn: computed(() => store.getters.sortColumnAlboSnm),
      sortOrder: computed(() => store.getters.sortOrderAlboSnm),
      setFetchRowsAlboSnm,
      setCurrentPageAlboSnm,
      setSortOrderColumn,
      selectedPersona,
      getCorsiAggiornamentoSnm,
      corsi_aggiornamento_snm_list,
      loadingDetail,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
};
</script>

<style scoped></style>
