<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_add-albo-snm">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        v-if="!showDetail"
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
        v-slot="{ values }"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Inserimento rinnovo annuale albo</h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <form autocomplete="off">
              <div class="card-body p-6">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <div class="ps-5 pe-5">
                  <div class="row pb-4">
                    <div class="col-md-6 filter">
                      <div class="dp__input_wrap">
                        <label class="fw-bold text-gray-700">Persona*</label>
                        <input
                          name="nome_persona"
                          type="text"
                          class="form-control"
                          autocomplete="off"
                          @input="
                            inputFocus = 'persona';
                            getTesserati($event.target.value);
                            nome_persona === ''
                              ? (id_persona = null)
                              : (id_persona = 0);
                          "
                          v-model="nome_persona"
                        />
                        <Field
                          name="id_persona"
                          type="hidden"
                          v-model="id_persona"
                          class="form-control"
                        />
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          class="dp__icon dp__clear_icon dp__input_icons"
                          @click="resetField()"
                        >
                          <path
                            d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                          ></path>
                          <path
                            d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                          ></path>
                        </svg>
                      </div>
                      <ListTesserati
                        v-if="inputFocus === 'persona'"
                        :list="tesseratiList"
                        @hideList="
                          inputFocus = null;
                          resetTesserati;
                        "
                        @selectedTesserato="
                          resetTesserati();
                          nome_persona = $event.name;
                          id_persona = $event.value;
                          inputFocus = null;
                        "
                      ></ListTesserati>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_persona"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-700">Stagione*</label>
                      <SelectInput
                        :options="stagioni"
                        name="anno"
                        placeholder="Seleziona"
                        :value="anno"
                        @changeSelect="setStagione($event)"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-700">Tipo albo*</label>
                      <input
                        type="text"
                        class="form-control"
                        value="Insegnanti"
                        aria-label=""
                        readonly
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-700"
                        >Data emissione*</label
                      >
                      <Datepicker
                        v-model="data_emissione"
                        @update:modelValue="
                          $event
                            ? (data_emissione = fromUTCtoISODate($event))
                            : ''
                        "
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                      />
                      <Field
                        name="data_emissione"
                        type="hidden"
                        v-model="data_emissione"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_emissione"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-700"
                        >Tipo pagamento*</label
                      >
                      <SelectInput
                        :options="pagamento_tipo_uso_albo"
                        name="id_tipo_pagamento"
                        placeholder="Seleziona"
                        :value="id_tipo_pagamento"
                        @changeSelect="setPagamentoTipo($event)"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-700">Mora</label>
                      <Field
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label=""
                        v-model="importo_mora"
                        @input="
                          importo_mora = fixDecimal(
                            $event,
                            'importo_mora',
                            values
                          )
                        "
                        name="importo_mora"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="importo_mora"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-700"
                        >Tassa reintegro</label
                      >
                      <div class="mt-2">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="flexcheckboxDefault"
                          v-model="tassa_reintegro"
                        />
                        <Field
                          type="hidden"
                          name="tassa_reintegro"
                          v-model="tassa_reintegro"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row pb-4" v-show="id_tipo_pagamento == 1">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-700"
                        >Data pagamento*</label
                      >
                      <Datepicker
                        v-model="data_competenza"
                        @update:modelValue="
                          $event
                            ? (data_competenza = fromUTCtoISODate($event))
                            : ''
                        "
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                      />
                      <Field
                        name="data_competenza"
                        type="hidden"
                        v-model="data_competenza"
                        class="form-control"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="data_competenza"
                      />
                    </div>
                    <div class="col-md-5">
                      <label class="fw-bold text-gray-700"
                        >Banca / Agenzia*</label
                      >
                      <Field
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label=""
                        name="banca"
                        v-model="banca"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="banca"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="fw-bold text-gray-700"
                        >Riferimenti / Agenzia*</label
                      >
                      <Field
                        type="text"
                        class="form-control"
                        placeholder=""
                        aria-label=""
                        name="agenzia"
                        v-model="agenzia"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="agenzia"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light text-gray-700 fs-6 text-start d-none"
              data-bs-toggle="modal"
              data-bs-target="#modal_add_pagamento_albo_snm"
              id="OpenAddPagamentoAlboSnm"
            ></button>
            <span
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalAlboSnm"
            >
              Chiudi
            </span>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
            >
              Continua
            </button>
          </div>
        </div>
      </Form>
      <Form
        v-if="showDetail"
        class="form"
        @submit="onSubmitPagamento"
        v-slot="{ values }"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Conferma pagamento - riepilogo movimenti in addebito
            </h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <div class="alert alert-danger" role="alert" v-if="alertCampiObb">
                Compila i campi obbligatori
              </div>
              <div class="ps-5 pe-5">
                <div class="row pb-4">
                  <div class="col-md-12 filter">
                    <div class="dp__input_wrap">
                      <label class=""
                        >Prospetto pagamento per:
                        <b>{{ listImporti[0].persona }} </b></label
                      >
                    </div>
                    <div class="dp__input_wrap">
                      <label class=""
                        >Data ultima qualifica acquisita:
                        <b>
                          {{
                            listImporti[0].data_ultima_qualifica_acquisita
                          }}</b
                        ></label
                      >
                    </div>
                    <div class="dp__input_wrap">
                      <label class=""
                        >Anno utima iscrizione albo:
                        <b>
                          {{ listImporti[0].ultima_iscrizione_albo }}
                        </b></label
                      >
                    </div>
                  </div>
                </div>

                <div class="table-responsive card shadow">
                  <table class="table table-striped gy-3 gs-7 align-middle">
                    <thead>
                      <tr
                        class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                      >
                        <th style="width: 33.33333333%">Quota</th>
                        <th style="width: 33.33333333%">Importo</th>
                        <th style="width: 33.33333333%">Importo proposto</th>
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray name="importi">
                        <tr
                          v-for="(item, index) in listImporti[0].report"
                          :key="index"
                        >
                          <td style="width: 33.33333333%">{{ item.quota }}</td>
                          <td style="width: 33.33333333%">
                            <div
                              class="input-group mb-3 input-group-sm pt-2 pe-5"
                            >
                              <Field
                                v-if="item.quota == 'Mora'"
                                name="importo_mora"
                                type="text"
                                :value="importo_mora.toString()"
                                min="0"
                                class="form-control"
                                @input="
                                  listImporti[0].report[
                                    index
                                  ].importo_unitario =
                                    $event.target.value != ''
                                      ? parseFloat($event.target.value).toFixed(
                                          2
                                        )
                                      : 0;
                                  changeTotaleProposto(values);
                                "
                                placeholder="0"
                              />
                              <Field
                                v-if="item.quota == 'Quota reintegro'"
                                name="importo_reintegro"
                                type="text"
                                :value="importo_reintegro.toString()"
                                class="form-control"
                                @input="
                                  listImporti[0].report[
                                    index
                                  ].importo_unitario =
                                    $event.target.value != ''
                                      ? parseFloat($event.target.value).toFixed(
                                          2
                                        )
                                      : 0;
                                  changeTotaleProposto(values);
                                "
                                placeholder="0"
                              />
                              <ErrorMessage
                                class="help-message"
                                style="color: red"
                                name="importo_reintegro"
                              />
                              <Field
                                v-if="
                                  item.quota != 'Mora' &&
                                  item.quota != 'Quota reintegro'
                                "
                                :name="`importi[${index}].anno`"
                                type="hidden"
                                :value="item.anno"
                                class="form-control"
                                placeholder="0"
                              />
                              <Field
                                v-if="
                                  item.quota != 'Mora' &&
                                  item.quota != 'Quota reintegro'
                                "
                                :name="`importi[${index}].importo`"
                                type="text"
                                :value="item.importo_unitario.toString()"
                                @input="
                                  listImporti[0].report[
                                    index
                                  ].importo_unitario =
                                    $event.target.value != ''
                                      ? parseFloat(
                                          parseFloat(
                                            $event.target.value
                                          ).toFixed(2)
                                        )
                                      : 0;
                                  changeTotaleProposto(values);
                                "
                                class="form-control"
                                style="
                                  font-size: inherit;
                                  border-top-left-radius: 0.475rem !important;
                                  border-bottom-left-radius: 0.475rem;
                                "
                                placeholder="0"
                              />
                              <span class="input-group-text h-28">€</span>
                            </div>
                          </td>
                          <td style="width: 33.33333333%">
                            {{ item.importo_proposto.toFixed(2) + " €" }}
                          </td>
                        </tr></FieldArray
                      >
                    </tbody>
                  </table>
                  <table class="table table-striped gy-3 gs-7 align-middle">
                    <thead>
                      <tr
                        class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                      >
                        <th style="width: 33.33333333%"></th>
                        <th style="width: 33.33333333%"></th>
                        <th style="width: 33.33333333%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="width: 33.33333333%"><b>Totale</b></td>
                        <td style="width: 33.33333333%">
                          <div class="pe-5">
                            <input
                              type="text"
                              class="form-control"
                              :value="totale_importi + ' €'"
                              name="totale_importi"
                              readonly
                            />
                          </div>
                        </td>
                        <td style="width: 33.33333333%">
                          {{ listImporti[0].totale_proposto.toFixed(2) + " €" }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <span
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              @click="showDetail = false"
            >
              Annulla
            </span>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
            >
              Conferma
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";

import "vue3-date-time-picker/dist/main.css";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
import { fromUTCtoISODate } from "@/composables/formatDate";

import ListTesserati from "@/components/components-fit/utility/ListTesserati.vue";

import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Form, ErrorMessage, Field, FieldArray } from "vee-validate";
import * as yup from "yup";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import {
  listRinnovoAnnualeAlbo,
  addRinnovoAnnualeAlbo,
} from "@/requests/alboInsegnateRequests";

export default {
  name: "AddAlboSnm",
  emits: ["refreshList"],
  components: {
    ListTesserati,
    Loading,
    Datepicker,
    SelectInput,
    Form,
    Field,
    FieldArray,
    ErrorMessage,
  },
  props: {},
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const showDetail = ref(false);

    const id_persona = ref(null);
    const nome_persona = ref(null);
    const anno = ref(null);
    const data_emissione = ref(fromUTCtoISODate(new Date()));
    const id_tipo_pagamento = ref(null);
    const importo_mora = ref(null);
    const tassa_reintegro = ref(false);
    const data_competenza = ref();
    const banca = ref(null);
    const agenzia = ref(null);

    const totale_importi = ref(null);
    const descrizione = ref(null);
    const anni_rinnovi_importi = ref(null);
    const importo_reintegro = ref(null);

    const pagamento_tipo_uso_albo = computed(() =>
      store.getters.getStateFromName("pagamento_tipo_uso_albo")
    );

    const keys = ref("ptua");
    if (!pagamento_tipo_uso_albo.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const getTesserati = (string) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getAlboSnmPersone", string);
    };
    const resetTesserati = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const spliceTesserati = () => {
      nome_persona.value = "";
      id_persona.value = null;
      resetTesserati();
    };

    const setStagione = (id) => {
      anno.value = parseInt(id);
    };

    const setPagamentoTipo = (id) => {
      id_tipo_pagamento.value = id;
    };

    const isLoading = ref(false);
    const alertCampiObb = ref(false);

    const schema = yup.object().shape({
      id_persona: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable()
        .moreThan(0, "Obbligatorio"),
      anno: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      data_emissione: yup.string().required("Obbligatorio").nullable(),
      data_competenza: yup.string().when("id_tipo_pagamento", {
        is: (id_tipo_pagamento) => id_tipo_pagamento == 1,
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
      banca: yup.string().when("id_tipo_pagamento", {
        is: (id_tipo_pagamento) => id_tipo_pagamento == 1,
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
      agenzia: yup.string().when("id_tipo_pagamento", {
        is: (id_tipo_pagamento) => id_tipo_pagamento == 1,
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
      id_tipo_pagamento: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      importo_mora: yup
        .number()
        .nullable()
        .transform((value) => (isNaN(value) ? undefined : value)),
      importo_reintegro: yup.number().typeError("Inserire un numero valido"),
      // .test(
      //   "is-decimal",
      //   "The amount should be a decimal with maximum two digits after comma",
      //   (val) => {
      //
      //     if (val != undefined) {
      //       return patternTwoDigisAfterComma.value.test(val);
      //     }
      //     return true;
      //   }
      // ),
      importi: yup.array().of(
        yup.object({
          importo: yup.number(),
          // .test(
          //   "is-decimal",
          //   "The amount should be a decimal with maximum two digits after comma",
          //   (val) => {
          //
          //     if (val != undefined) {
          //       return patternTwoDigisAfterComma.value.test(val);
          //     }
          //     return true;
          //   }
          // ),
        })
      ),
      // .test(
      //   "is-decimal",
      //   "The amount should be a decimal with maximum two digits after comma",
      //   (val) => {
      //     if (val != undefined) {
      //       return patternTwoDigisAfterComma.value.test(val);
      //     }
      //     return true;
      //   }
      // ),
    });

    const listImporti = ref([]);
    const report = ref(null);

    const onSubmit = (values) => {
      isLoading.value = true;
      alertCampiObb.value = false;
      listRinnovoAnnualeAlbo(values).then((res) => {
        isLoading.value = false;
        if (res.status == 200) {
          listImporti.value = res.data.results;
          totale_importi.value = listImporti.value[0].totale_proposto;
          if (listImporti.value[0].report) {
            report.value = listImporti.value[0].report;
            report.value.forEach((element) => {
              if (element.quota == "Quota reintegro") {
                importo_reintegro.value = element.importo_unitario;
              }
            });
          }
          if (listImporti.value[0].totale_proposto) {
            showDetail.value = true;
          } else {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          }
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
      });
    };

    const anni_importi = ref([]);

    // watch(listImporti, () => {
    //
    //   totale_importi.value = null;
    //   listImporti.value[0].report.forEach((element) => {
    //     totale_importi.value += element.importo_unitario;
    //   });
    // });
    const changeTotaleProposto = (valuesToFix) => {
      if (valuesToFix.importo_mora) {
        valuesToFix.importo_mora =
          valuesToFix.importo_mora.indexOf(".") >= 0
            ? valuesToFix.importo_mora.substr(
                0,
                valuesToFix.importo_mora.indexOf(".")
              ) +
              valuesToFix.importo_mora.substr(
                valuesToFix.importo_mora.indexOf("."),
                3
              )
            : valuesToFix.importo_mora;
      }
      if (valuesToFix.importo_reintegro) {
        valuesToFix.importo_reintegro =
          valuesToFix.importo_reintegro.indexOf(".") >= 0
            ? valuesToFix.importo_reintegro.substr(
                0,
                valuesToFix.importo_reintegro.indexOf(".")
              ) +
              valuesToFix.importo_reintegro.substr(
                valuesToFix.importo_reintegro.indexOf("."),
                3
              )
            : valuesToFix.importo_reintegro;
      }
      valuesToFix.importi.forEach((element) => {
        element.importo =
          element.importo.indexOf(".") >= 0
            ? element.importo.substr(0, element.importo.indexOf(".")) +
              element.importo.substr(element.importo.indexOf("."), 3)
            : element.importo;
      });

      totale_importi.value = 0;
      // listImporti.value[0].report.forEach((element) => {
      //   element.importo_unitario = parseFloat(
      //     parseFloat(element.importo_unitario).toFixed(2)
      //   );
      //
      //   totale_importi.value += parseFloat(
      //     parseFloat(element.importo_unitario).toFixed(2)
      //   );
      //   totale_importi.value = parseFloat(
      //     parseFloat(totale_importi.value).toFixed(2)
      //   );
      // });
      totale_importi.value += parseFloat(valuesToFix.importo_mora)
        ? parseFloat(valuesToFix.importo_mora)
        : 0;
      totale_importi.value += valuesToFix.importo_reintegro
        ? parseFloat(valuesToFix.importo_reintegro)
        : 0;
      valuesToFix.importi.forEach((element) => {
        totale_importi.value += element.importo
          ? parseFloat(element.importo)
          : 0;
      });
      totale_importi.value = parseFloat(
        parseFloat(totale_importi.value).toFixed(2)
      );
    };

    const onSubmitPagamento = (values) => {
      isLoading.value = true;
      anni_importi.value = [];
      if (values.importi) {
        values.importi.forEach((element) => {
          if (element.anno) {
            element.importo = parseFloat(element.importo).toFixed(2);
            element.importo = parseFloat(element.importo);
            anni_importi.value.push(element);
          }
        });
      }
      descrizione.value =
        id_tipo_pagamento.value == 1 ? banca.value + " - " + agenzia.value : "";
      anni_rinnovi_importi.value = JSON.stringify(anni_importi.value);
      addRinnovoAnnualeAlbo(
        id_persona.value,
        anno.value,
        data_emissione.value,
        id_tipo_pagamento.value,
        descrizione.value,
        data_competenza.value,
        anni_rinnovi_importi.value,
        values.importo_reintegro,
        values.importo_mora,
        null
      ).then((res) => {
        isLoading.value = false;
        if (res.status == 200) {
          alertSuccess(
            "Rinnovo albo completato con successo",
            "kt_modal_add-albo-snm"
          );
          emit("refreshList");
          resetField();
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "kt_modal_add-albo-snm"
          );
          resetField();
        }
      });
    };

    const onInvalidSubmit = () => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      spliceTesserati();
      id_persona.value = null;
      nome_persona.value = null;
      anno.value = null;
      data_emissione.value = fromUTCtoISODate(new Date());
      id_tipo_pagamento.value = null;
      importo_mora.value = null;
      tassa_reintegro.value = false;
      data_competenza.value = null;
      banca.value = null;
      agenzia.value = null;
      listImporti.value = [];
      showDetail.value = false;
      anni_rinnovi_importi.value = null;
    };

    const fixDecimal = (e, name, values) => {
      let t = e.target.value;
      values[name] =
        t.indexOf(".") >= 0
          ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)
          : t;

      return values[name];
    };

    return {
      fixDecimal,
      showDetail,
      id_persona,
      nome_persona,
      anno,
      data_emissione,
      id_tipo_pagamento,
      importo_mora,
      tassa_reintegro,
      data_competenza,
      banca,
      agenzia,
      totale_importi,
      importo_reintegro,
      tesseratiList: computed(() => store.getters.personeSocieta),
      stagioni: computed(() => store.getters.stagioni),
      pagamento_tipo_uso_albo,
      schema,
      onSubmit,
      onSubmitPagamento,
      onInvalidSubmit,
      isLoading,
      resetField,
      alertCampiObb,
      getTesserati,
      resetTesserati,
      spliceTesserati,
      setStagione,
      setPagamentoTipo,
      fromUTCtoISODate,
      format,
      listImporti,
      changeTotaleProposto,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}

.dp__clear_icon {
  position: absolute;
  top: 71%;
  right: -2px;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--dp-icon-color);
}
/* nasconde frecce type=number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* stile placeholder */
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000000;
  opacity: 1; /* Firefox */
}
</style>
